<template>
  <div class="resource-page-header">
    <div class="resource-header-box">
      <div class="subject-box">
        <img src="@/static/resHeader/kemu.png" alt="" />
        {{ levelName }}{{ subjectName }}
        <i class="el-icon-arrow-down"></i>
        <div class="subject-box-hover">
          <i class="el-icon-caret-top"></i>
          <template v-for="(v, i) in levelOptions">
            <div v-if="v.value != 4" :key="i" class="hover-list">
              <p>{{ v.label }}</p>
              <div
                class="hover-list-label"
                :class="{ 'hover-list-label-1': v.value == 1 }"
              >
                <template v-if="v.value == 3">
                  <span
                    v-for="(item, key) in highSubjectOptions"
                    :key="key + 'km'"
                    class="subject-item"
                    :class="{
                      on: item.value == subjectId && v.value == levelId,
                    }"
                    @click="subjectClick(v, item)"
                  >
                    {{ item.label }}
                  </span>
                </template>
                <template v-if="v.value == 2">
                  <span
                    v-for="(item, key) in juniorSubjectOptions"
                    :key="key + 'km'"
                    class="subject-item"
                    :class="{
                      on: item.value == subjectId && v.value == levelId,
                    }"
                    @click="subjectClick(v, item)"
                  >
                    {{ item.label }}
                  </span>
                </template>
                <template v-if="v.value == 1">
                  <span
                    v-for="(item, key) in primarySubjectOptions"
                    :key="key + 'km'"
                    class="subject-item"
                    :class="{
                      on: item.value == subjectId && v.value == levelId,
                    }"
                    @click="subjectClick(v, item)"
                  >
                    {{ item.label }}
                  </span>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div :key="newKey" class="page-list-box">
        <!-- {{ item.label }} -->
        <!-- {{ currentTab }} -->
        <div
          v-for="(item, key) in headerBtns"
          :key="key"
          class="page-list"
          :class="{ on: currentTab == item.label }"
          @click="tabHandleClick(item)"
        >
          <img v-if="currentTab == item.label" :src="item.imgOn" alt="" />
          <img
            v-else
            :src="item.img"
            alt=""
            :class="{ opImg: item.label == 'knowledgePoints' }"
          />
          <span>{{ item.name }}</span>
          <i
            v-if="item.children && item.children.length > 0"
            class="el-icon-arrow-down"
          ></i>
          <!-- 子菜单 -->
          <div
            v-if="item.children && item.children.length > 0"
            class="child-list"
          >
            <!-- 构建一个三角形 -->
            <ul>
              <li
                v-for="(m, n) in item.children"
                :key="'chill' + n"
                :class="{ childOn: m.label == $route.name }"
                @click.stop="tabHandleClick(m)"
              >
                {{ m.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  levelOptions,
  // oldLevelOptions,
  subjectOptions,
} from "@/core/util/constdata.js";
// import { store } from "@/core/services/store";
import { getStore } from "@/core/util/store";
import { filterSubject } from "@/core/util/util";
// import { mapMutations } from "vuex";
import { mapGetters } from "vuex";
export default {
  props: {
    subjectData: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      levelOptions: levelOptions().filter((item) => item.value != 4),
      // oldLevelOptions: oldLevelOptions,
      // oldSubjectOptions: ,
      subjectOptions: subjectOptions,
      juniorSubjectOptions: [],
      highSubjectOptions: [],
      primarySubjectOptions: [],
      currentTab: "boutique",
      levelId: "",
      subjectId: "",
      levelName: "",
      subjectName: "",
      newKey: new Date().getDate(),
      loadInit: true,
      headerBtns: [],
    };
  },
  computed: {
    ...mapGetters({
      getLevelId: "getLevelId",
      getSubjectId: "getSubjectId",
      getHeaderType: "getHeaderType",
      getEditObject: "getEditObject",
    }),
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.currentTab = this.$route.meta.tabName;
        // console.log(this.currentTab);
      },
    },
    getEditObject: {
      deep: true,
      handler() {
        // console.log(this.getEditObject);
        let obj1 = this.levelOptions.filter(
          (item) => item.value == this.getEditObject.levelId
        )[0];
        let obj2 = this.subjectOptions.filter(
          (item) => item.value == this.getEditObject.subjectId
        )[0];

        // obj2
        this.subjectClick(obj1, obj2, 2);
      },
    },
    getHeaderType() {
      this.loadInit = false;
      this.initOptions();
      this.setNewOnload();
    },
    getLevelId() {
      this.levelId = this.getLevelId;

      let obj1 = this.levelOptions.filter(
        (item) => item.value == this.levelId
      )[0];
      let obj2 = {};

      obj2 = this.subjectOptions.filter(
        (item) => item.value == this.subjectId
      )[0];

      this.levelName = obj1.label;
      this.subjectName = obj2.label;
    },
    getSubjectId() {
      this.subjectId = this.getSubjectId;
      let obj1 = this.levelOptions.filter(
        (item) => item.value == this.levelId
      )[0];
      let obj2 = {};

      obj2 = this.subjectOptions.filter(
        (item) => item.value == this.subjectId
      )[0];

      this.levelName = obj1.label;
      this.subjectName = obj2.label;
    },
  },
  created() {
    this.currentTab = this.$route.meta.tabName;
    // console.log(this.currentTab);
    this.initOptions();
    this.setOnload();
    // this.setNewOnload();
  },

  methods: {
    initOptions() {
      // 初始化科目选项
      // this.subjectOptions = [...this.oldSubjectOptions];

      // this.subjectOptions.splice(4, 0, { value: 11, label: "道德与法治" });
      this.juniorSubjectOptions = filterSubject(2);
      this.highSubjectOptions = filterSubject(3);
      this.primarySubjectOptions = [
        {
          value: 1,
          label: "语文",
          index: 1,
        },
        {
          value: 2,
          label: "数学",
          index: 2,
        },
        {
          value: 3,
          label: "英语",
          index: 3,
        },
        // {
        //   value: 10,
        //   label: "科学",
        //   index: 4,
        // },
        // { value: 11, label: "道德与法治", index: 5 },
      ];

      let arr = [
        {
          label: "multiplePaper",
          name: "试题库",
          children: [
            { label: "KnowledgePointsPaper", name: "知识点/同步组卷" },
            { label: "BreakdownPaper", name: "细目表组卷" },
            { label: "LearningPaper", name: "学情组卷", showTab: true },
          ],
          img: require("@/static/resHeader/zsd.png"),
          imgOn: require("@/static/resHeader/zsdOn.png"),
        },
        // {
        //   label: "",
        //   name: "学情组卷",
        //   img: require("@/static/resHeader/zn.png"),
        //   imgOn: require("@/static/resHeader/znOn.png")
        // },
        {
          label: "boutique",
          name: "试卷库",
          img: require("@/static/resHeader/jp.png"),
          imgOn: require("@/static/resHeader/jpOn.png"),
        },
        // {
        //   label: "boutiqueTeachingAids",
        //   name: "精品教辅",
        //   img: require("@/static/resHeader/jp.png"),
        //   imgOn: require("@/static/resHeader/jpOn.png")
        // },
        // {
        //   label: "boutiqueMicroLesson",
        //   name: "精品微课",
        //   img: require("@/static/resHeader/jp.png"),
        //   imgOn: require("@/static/resHeader/jpOn.png")
        // },
        {
          label: "schoolResource",
          name: "校本资源",
          img: require("@/static/resHeader/xb.png"),
          imgOn: require("@/static/resHeader/xbOn.png"),
        },
        {
          label: "myResource",
          name: "我的资源",
          img: require("@/static/resHeader/wdzy.png"),
          imgOn: require("@/static/resHeader/wdzyOn.png"),
        },
      ];
      let userInfo = getStore({ name: "userInfo" });
      this.headerBtns = arr.map((item) => {
        if (userInfo.schoolVersion == 2 && item.children) {
          item.children = item.children.filter((item) => !item.showTab);
        }
        return item;
      });
    },
    setNewOnload() {
      let userInfo = getStore({ name: "userInfo" });
      let obj1, obj2;
      //学段判断
      if (userInfo.levelId) {
        obj1 = this.levelOptions.filter(
          (item) =>
            item.value ==
            userInfo.levelId.split(",")[userInfo.levelId.split(",").length - 1]
        )[0];
        if (!obj1) {
          obj1 = this.levelOptions.filter(
            (item) => item.value == userInfo.schoolPeriod.split(",")[0]
          )[0];
        }
      } else {
        obj1 = this.levelOptions[this.levelOptions.length - 1];
      }
      //科目判断
      if (userInfo.teacherSubjectId) {
        obj2 = this.subjectOptions.filter(
          (item) => item.value == userInfo.teacherSubjectId.split(",")[0]
        )[0];
      } else {
        obj2 = this.subjectOptions.filter((item) => item.value == 2)[0];
      }
      this.subjectClick(obj1, obj2, 2);
    },
    setOnload() {
      let level = getStore({ name: "levelId" });
      let subject = getStore({ name: "subjectId" });

      // 判断  如果没有的话  就直接加载第一条数据
      if (level && level.value && subject && subject.value) {
        // let userInfo = getStore({ name: "userInfo" });
        let obj1, obj2;
        // if (!userInfo.levelId || !userInfo.teacherSubjectId) {
        obj1 = this.levelOptions.filter((item) => item.value == level.value)[0];
        obj2 = this.subjectOptions.filter(
          (item) => item.value == subject.value
        )[0];
        // }
        //  else {
        // obj1 = this.levelOptions.filter(
        //   item => item.value == userInfo.levelId[0]
        // )[0];
        if (!obj1) {
          obj1 = this.levelOptions[0];
        }
        if (!obj2) {
          obj2 = this.subjectOptions[0];
        }
        // console.log(obj2);
        // console.log(obj1);
        // }
        if (!obj1 && this.levelOptions.length > 0) {
          obj1 = this.levelOptions[this.levelOptions.length - 1];
        }
        this.subjectClick(obj1, obj2, 1);
      } else {
        if (this.levelOptions.length > 0) {
          let { teacherRoleList } = getStore({ name: "userInfo" });
          let obj1 = {};
          let obj2 = {};
          if (teacherRoleList && teacherRoleList.length > 0) {
            let array = teacherRoleList.filter(
              (item) => item.level && item.subjectId
            );
            if (array.length > 0) {
              obj1 = {
                value: array[0].level,
              };
              obj2 = {
                value: array[0].subjectId,
              };
            }
          }
          if (!obj1.value) {
            obj1 = this.levelOptions[this.levelOptions.length - 1];
            obj2 = this.subjectOptions[1];
          }
          //科目判断

          this.subjectClick(obj1, obj2, 1);
        }
      }
    },
    subjectClick(val, subVal, type) {
      // console.log(type);
      // console.log(subVal);
      // console.log(val);
      //type存在就是第一次进入
      if (val.value != this.levelId || subVal.value != this.subjectId) {
        this.$store.commit("setLevelId", val.value);
        this.$store.commit("setSubjectId", subVal.value);
        this.levelName = val.label;
        this.subjectName = subVal.label;
        if (type) {
          // this.currentTab = this.$route.name;
          this.levelId = val.value;
          this.subjectId = subVal.value;
          //判断用户信息 请求回来了更新组件
          if (type == 2) {
            this.$store.commit("setIsType");
          }
        } else {
          //切换数据后  将url清空
          this.$router.push({ query: {} });
          this.$store.commit("setIsType");
          this.$message({
            message: "数据切换中，请稍后!",
            type: "success",
            duration: 1000,
          });
        }
      }
    },
    tabHandleClick(value) {
      if (value.children && value.children.length > 0) {
        return;
      }

      this.$router.push({
        name: value.label,
        query: this.subjectData,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.resource-page-header {
  width: 100%;
  min-width: 100vw;
  background-color: #ffffff;
  position: fixed;
  top: 74px;
  left: 0;
  z-index: 888;
  border-bottom: 1px solid #f3f3f3;
  // height: 74px;
  // padding-left: calc(100vw - 100%);
  img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  .resource-header-box {
    width: 1220px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .subject-box {
      margin-right: 30px;
      cursor: pointer;
      position: relative;
      z-index: 1;
      padding: 12.5px 15px 12.5px 0;
      &:hover {
        .subject-box-hover {
          display: block;
        }
      }
      .subject-box-hover {
        left: 0;
        top: 46px;
        position: absolute;
        border: 1px solid $primary-color;
        padding: 8px 14px 28px 14px;
        background-color: #f5f5f5;
        width: 412px;
        display: none;
        cursor: auto;
        .el-icon-caret-top {
          position: absolute;
          left: 36px;
          top: -18px;
          font-size: 25px;
        }
        p {
          color: rgba(0, 0, 0, 0.85);
          font-weight: bold;
          margin-top: 20px;
        }
        .hover-list-label.hover-list-label-1 {
          justify-content: flex-start;
          span {
            margin-right: 6px;
          }
        }
        .hover-list-label {
          display: flex;
          justify-content: space-between;
          color: rgba(0, 0, 0, 0.85);
          .subject-item {
            cursor: pointer;
          }
          span {
            white-space: nowrap;
            &:hover {
              color: $primary-color;
            }
          }
          .on {
            color: $primary-color;
          }
        }
      }
      i {
        color: $primary-color;
      }
      color: $primary-color;
    }
    .page-list-box {
      display: flex;
      align-items: center;
      .opImg {
        opacity: 0.6;
      }
      .page-list {
        padding: 12.5px 15px;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        height: 48px;
        .child-list {
          visibility: hidden;
          opacity: 0;
          position: absolute;
          width: 146px;
          padding: 8px 0;
          top: 76px;
          left: 50%;
          transform: translateX(-50%);
          -ms-transform: translateX(-50%); /* IE 9 */
          -moz-transform: translateX(-50%);
          /*Firefox*/
          -webkit-transform: translateX(-50%); /* Safari 和 Chrome */
          -o-transform: translateX(-50%); /* Opera */
          background-color: #ffffff;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          border: 1px solid #ebeef5;
          border-radius: 5px;
          z-index: 10;
          transition: 0.3s linear;
          -moz-transition: 0.3s linear; /* Firefox 4 */
          -webkit-transition: 0.3s linear; /*Safari 和 Chrome */
          -o-transition: 0.3s linear; /* Opera */

          &::after {
            content: "";
            position: absolute;
            top: -8px;
            left: 50%;
            transform: translateX(-50%);
            -ms-transform: translateX(-50%); /* IE 9 */
            -moz-transform: translateX(-50%);
            /*Firefox*/
            -webkit-transform: translateX(-50%); /* Safari 和 Chrome */
            -o-transform: translateX(-50%); /* Opera */
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid #ffffff;
          }

          ul {
            li {
              padding: 0 10px;
              line-height: 34px;
              &:hover {
                color: #2474ed;
              }
            }
            .childOn {
              background-color: #2474ed;
              color: #ffffff;
              &:hover {
                color: #ffffff;
              }
            }
          }
        }
        &:hover {
          // i {
          //   color: $primary-color;
          // }
          span {
            color: $primary-color;
          }

          .child-list {
            top: 45px;
            opacity: 1;
            visibility: initial;
            transition: 0.3s linear;
            -moz-transition: 0.3s linear; /* Firefox 4 */
            -webkit-transition: 0.3s linear; /*Safari 和 Chrome */
            -o-transition: 0.3s linear; /* Opera */
          }
        }
        i {
          margin-left: 10px;
        }
      }
      .on {
        background-color: white;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        i {
          color: $primary-color;
        }
        color: $primary-color;
      }
    }
  }
}
</style>
