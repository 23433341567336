<template>
  <div :class="{ myResIndex: showMenu }" class="res-box">
    <resourceTab v-show="showMenu"></resourceTab>
    <!-- <keep-alive>
      <router-view></router-view>
    </keep-alive> -->
    <transition>
      <keep-alive :include="allKeepAlive">
        <router-view :key="isRouterAlive"></router-view>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import resourceTab from "@/components/resourceTab.vue";
import { mapGetters } from "vuex";
export default {
  name: "MyResIndex",
  components: {
    resourceTab,
  },
  data() {
    return {
      isRouterAlive: 1,
      showMenu: true,
      allKeepAlive: [],
    };
  },
  computed: {
    ...mapGetters(["getIsType", "getIsShowMenue", "getKeepArr"]),
  },

  watch: {
    getKeepArr: {
      immediate: true,
      handler: function () {
        // console.log(this.getKeepArr);
        // console.log(this.allKeepAlive);
        this.allKeepAlive = [...this.getKeepArr];
        // console.log(this.getKeepArr);
        // console.log(this.$route.meta.keepAlive);
      },
    },
    getIsType() {
      this.isRouterAlive = new Date().getTime();
      // console.log("21111111111111");
      // this.isRouterAlive = false;
      // this.$nextTick(() => {
      //   this.isRouterAlive = true;
      // });
      //
    },
    getIsShowMenue() {
      // console.log(this.getIsShowMenue);
      this.showMenu = this.getIsShowMenue;
    },
  },
  created() {
    // console.log("重新执行了");
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.myResIndex {
  margin-top: 50px;
}
.res-box {
  background-color: #ffffff;
}
</style>
